import { ExtendRepoObjects } from "./createJob";
import { GetRepoObjectQueryData } from "../../../backend/types";
import { getFlattenedTree } from "../../../helpers/treeHelper";

/**
 * Retrieves the repository object for execution based on the provided execution file source.
 *
 * @param {string} executionFileSource - The source of the execution file, either "phil" or another environment.
 * @param {Function} getFileVersions - Function to get file versions from the repository.
 * @param {GetRepoObjectQueryData[]} repos - Array of repository objects from PhIL or IE files.
 * @param {ExtendRepoObjects} executionRepoObject - The execution repository object containing repository and version details.
 * @returns {Promise<{ repositoryId: string; versionId: string; absolutePath: string; notFound: boolean }>} - A promise that resolves to an object containing repository ID, version ID, absolute path, and a notFound flag.
 */

export const getRepoObjectForExecution = (
  executionFileSource: string,
  getFileVersions: any,
  repos: GetRepoObjectQueryData[], // from phil or IE files
  executionRepoObject: ExtendRepoObjects
) => {
  const flattenRepoObjects = getFlattenedTree(repos!);

  switch (executionFileSource) {
    case "phil": {
      // we always get the latest version in PhIL by
      // either the versionId or the absolutePath
      const repoObject = flattenRepoObjects.find((object: any) => {
        return (
          object.versionId === executionRepoObject.versionId ||
          object.repo.name + "/" + object.absolutePath ===
            executionRepoObject.absolutePath
        );
      });

      if (repoObject?.name) {
        return {
          repositoryId: repoObject.repo?.id,
          versionId: repoObject.versionId,
          absolutePath: `${repoObject.repo?.name}/${repoObject.absolutePath}`,
          notFound: false
        };
      } else {
        // when the version is not found in phil,
        // we request history on this version to find the name of the file
        return getFileVersions({
          variables: {
            repoId: executionRepoObject.repositoryId,
            versionId: executionRepoObject.versionId
          }
        })
          .then(({ data }: any) => {
            const versionsHistory = data.getHistoryVersions[0];
            // identify if the latest version is already in PhIL, and retrieve repo object
            // if not found, then file does not exist
            const latestRepoObject = flattenRepoObjects.find(
              (repoObject: any) =>
                `${repoObject.repo.name}/${repoObject.absolutePath}` ===
                `${versionsHistory.repo.name}/${versionsHistory.name}`
            );

            return {
              repositoryId: executionRepoObject.repositoryId,
              versionId: latestRepoObject
                ? latestRepoObject.versionId
                : versionsHistory.versionId,
              absolutePath: `${versionsHistory.repo.name}/${versionsHistory.name}`,
              notFound: latestRepoObject ? false : true
            };
          })
          .catch(() => {
            return {
              repositoryId: executionRepoObject.repositoryId,
              versionId: executionRepoObject.versionId,
              absolutePath: "",
              notFound: true
            };
          });
      }
    }
    default: // when environment is not phil
      // we always get file version for IE to retrieve name of file
      if (
        executionRepoObject.versionId === "" ||
        executionRepoObject.versionId === null
      ) {
        const foundFile = flattenRepoObjects.find(
          (file: any) =>
            file.repo.name + "/" + file.absolutePath ===
            executionRepoObject.absolutePath
        );

        return {
          repositoryId: executionRepoObject.repositoryId,
          versionId: foundFile?.versionId || "",
          absolutePath: executionRepoObject.absolutePath,
          notFound: foundFile ? false : true
        };
      } else {
        return getFileVersions({
          variables: {
            repoId: executionRepoObject.repositoryId,
            versionId: executionRepoObject.versionId
          }
        })
          .then(({ data }: any) => {
            // find by file name by the version id,
            // then locate the file in IE file system by the name

            const file = data.getHistoryVersions[0];
            const foundFile = flattenRepoObjects.find(
              (repoObject: any) =>
                repoObject.repo.id === file.repo.id &&
                `${repoObject.repo.name}/${repoObject.absolutePath}` ===
                  `${file.repo.name}/${file.name}`
            );

            return {
              repositoryId: executionRepoObject.repositoryId,
              versionId: executionRepoObject.versionId,
              absolutePath: `${file.repo.name}/${file.name}`,
              notFound: !foundFile
            };
          })
          .catch(() => {
            return {
              repositoryId: executionRepoObject.repositoryId,
              versionId: executionRepoObject.versionId,
              absolutePath: "",
              notFound: true
            };
          });
      }
  }
};

export const getFileNameAndRepoName = (file: any) => {
  const paths = file.split("/");
  const repoName = paths[0];
  const fileName = paths.slice(1).join("/");

  return [repoName, fileName];
};
