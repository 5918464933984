import { RepoObjectInput } from "../../../backend/executionTypes";
import { GetRepoObjectQueryData, PmxActivity } from "../../../backend/types";
import { getFlattenedTree } from "../../../helpers/treeHelper";
import { getFileNameAndRepoName } from "./fileHelper";

export const setExecutionSettingRecommendation = (
  environmentType: string,
  setExecutionImage: any,
  setCustomCommand: any,
  setExecutionInput: any,
  getCommandRecommendation: any,
  getImageRecommendation: any,
  getExecutionInputRecommendation: any,
  variables: {
    activityId?: number;
    environmentId?: string;
    path?: string;
    entrypoint?: RepoObjectInput;
  },
  activityData?: PmxActivity,
  repos?: GetRepoObjectQueryData[]
) => {
  const flattenRepoObjects = getFlattenedTree(repos!);
  if (environmentType === "phil") {
    getExecutionInputRecommendation({
      variables: variables
    }).then(({ data }: any) => {
      // always main activity
      const dataInputs = data.getInputRecommendations.inputs.map(
        (file: string) => ({
          repositoryId: activityData?.mainRepository?.id,
          versionId: flattenRepoObjects.find((object: any) => {
            return (
              object.repo.name + "/" + object.absolutePath === file.substring(1)
            );
          })?.versionId,
          absolutePath: file.substring(1) //backend always return / in beginning of file
        })
      );
      if (dataInputs.length > 0) {
        setExecutionInput(dataInputs);
      }
    });

    getImageRecommendation({
      variables: {
        activityId: variables.activityId,
        entrypoint: variables.entrypoint
      }
    }).then(({ data }: any) => {
      if (data.getImageRecommendations.images.length > 0) {
        const image = data.getImageRecommendations.images[0].id.toString();
        setExecutionImage({ id: image });
        setCustomCommand(getCommandRecommendation(image));
      }
    });
  } else {
    const [repoName, fileName] = getFileNameAndRepoName(variables.path);
    getExecutionInputRecommendation({
      variables: {
        environmentId: variables.environmentId,
        repoName: repoName,
        path: fileName
      }
    }).then(({ data }: any) => {
      // always main activity
      const dataInputs = data.getExecutionInputRecommendations.inputs.map(
        (file: any) => ({
          repositoryId: activityData?.mainRepository?.id,
          versionId: "",
          absolutePath: `${file.repoName}/${file.path}`
        })
      );
      if (dataInputs.length > 0) {
        setExecutionInput(dataInputs);
      }
    });

    getImageRecommendation({
      variables: {
        environmentId: variables.environmentId,
        repoName: repoName,
        path: fileName
      }
    }).then(({ data }: any) => {
      if (data.getExecutionImageRecommendations.images.length > 0) {
        const image =
          data.getExecutionImageRecommendations.images[0].toString();
        setExecutionImage({ id: image });
        setCustomCommand(getCommandRecommendation(image));
      }
    });
  }
};
